
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "vpn-connection-box",
    components: {},
    setup() {
        const store = useStore();
        const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
        store.dispatch(Actions.GET_VPN_CONFIG);
      const user = computed(() => {
            return store.getters.currentUser;
        });
        
        const currentVPN = computed(() => {
            return store.getters.getCurrentVPN;
        });
        
    return {
        user,
        currentVPN,
        store,
        currentLanguage
    };
    },
    methods: {
        downloadVPN() {
            var a = document.createElement("a"); //Create <a>
            a.href = "data:application/octet-stream;base64," + this.currentVPN.vpn_file; //Image Base64 Goes here
            a.download = "hackingclub-vpn.zip"; //File name Here
            a.click(); //Downloaded file
            
        }
    }
});
