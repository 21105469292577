
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

import  connectionBox from "./connection-box.vue";
import { Actions } from "@/store/enums/StoreEnums";



export default defineComponent({
  name: "vpn-component",
  data() {
    return {
       vpn: {
        ip: "",
        online: false
        }
    }
    },
    components: {
        connectionBox,
        
        
    },
    setup() {
      const store = useStore();
      
      const user = computed(() => {
        return store.getters.currentUser;
      });
     
      return {
        user,
      
      };
    }
});
